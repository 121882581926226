import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ActionsService from 'services/ActionsService';
import TravauxService from 'services/TravauxService';

export const initialState = {
	loadingActionsDetails: false,
	loadingActionsList: false,
	loadingDraftsActionsList: false,
	loadingJobsList: false,
	
	messageActions: '',
	
	actionsCreatedId: '',
	actionsUpdatedId: '',
	actionsPublished: false,
	actionsDeleted: false,
	
	actionsDetails: [],
	listActionsApi: [],
	listDraftsActionsApi: [],
	listJobsListApi: [],
}

export const createDraftsActions = createAsyncThunk('actions/createDraftsActions', async (draftsActionsData, { rejectWithValue }) => {
	const { id_action, libelle, id_metier, action_thumbnail, list_travaux, list_videos, } = draftsActionsData
	try {
		var response;
		if (id_action) {
			response = await ActionsService.createDraftsActions({ libelle, id_metier, id_action_maitre: id_action })
		} else {
			response = await ActionsService.createDraftsActions({ libelle, id_metier })

			if (action_thumbnail) {
				const tempId = response.data.id
				const formData = new FormData();
				formData.append('brouillon_action_photo', action_thumbnail);
				await ActionsService.addActionsThumbnailById(tempId, formData)
			}
			
			if (list_travaux && list_travaux?.length > 0) {
				for (const travaux of list_travaux) {
					if (travaux?.checked && !travaux?.api) {
						await TravauxService.updateQuestionReponse(travaux.id_question, travaux.id_answer, { id_action_brouillon: response.data.id });
					}
				}
			}
			
			if (list_videos && list_videos.length > 0) {
				for (const video of list_videos) {
					const tempId = response.data.id;
					const formData = new FormData();
					formData.append('brouillon_action_video', video); 
					formData.append('file_video_angle_code', video.videoAngle); 
					await ActionsService.addActionsVideoById(tempId, formData);
				}
			}
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getActionsList = createAsyncThunk('actions/getActionsList', async (_, { rejectWithValue }) => {
	try {
		const response = await ActionsService.getActionsList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDraftsActionsList = createAsyncThunk('actions/getDraftsActionsList', async (_, { rejectWithValue }) => {
	try {
		const response = await ActionsService.getDraftsActionsList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getJobsList = createAsyncThunk('actions/getJobsList', async (_, { rejectWithValue }) => {
	try {
		const response = await ActionsService.getJobsList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// ID
export const publishDraftsActionsById = createAsyncThunk('actions/publishDraftsActionsById',async (actionsId, { rejectWithValue }) => {
	try {
		const response = await ActionsService.publishDraftsActionsById(actionsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getActionsById = createAsyncThunk('actions/getActionsById', async (actionsId, { rejectWithValue }) => {
	try {
		const response = await ActionsService.getActionsById(actionsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDraftsActionsById = createAsyncThunk('actions/getDraftsActionsById', async (draftsActionsId, { rejectWithValue }) => {
	try {
		const response = await ActionsService.getDraftsActionsById(draftsActionsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateDraftsActionsById = createAsyncThunk('actions/updateDraftsActionsById', async (draftsActionsData, { rejectWithValue }) => {
	const { id_action, libelle, id_metier, action_thumbnail, list_travaux, list_videos, list_videos_deleted } = draftsActionsData
	try {
		const response = await ActionsService.updateDraftsActionsById(id_action, { libelle, id_metier })
		
		if (action_thumbnail) {
			const formData = new FormData();
			formData.append('brouillon_action_photo', action_thumbnail);
			await ActionsService.deleteActionsThumbnailById(id_action);
			await ActionsService.addActionsThumbnailById(id_action, formData);
		}
		
		if (list_travaux && list_travaux?.length > 0) {
			for (const travaux of list_travaux) {
				if (travaux?.checked && !travaux?.api) {
					await TravauxService.updateQuestionReponse(travaux.id_question, travaux.id_answer, { id_action_brouillon: response.data.id });
				} else if (!travaux?.checked && travaux?.api) {
					await TravauxService.updateQuestionReponse(travaux.id_question, travaux.id_answer, { id_action_brouillon: null });
				}
				
			}
		}
		
		if (list_videos_deleted && list_videos_deleted.length > 0) {
			for (const video_deleted of list_videos_deleted) {
				await ActionsService.deleteActionsVideoById(id_action, {id_file: video_deleted.uid});
			}
		}
		
		if (list_videos && list_videos.length > 0) {
			for (const video of list_videos) {
				const fileName = video.name;
				const regex = /videoApi\d+/; 
				if (!(regex.test(fileName))) {
					const formData = new FormData();
					formData.append('brouillon_action_video', video); 
					formData.append('file_video_angle_code', video.videoAngle); 
					await ActionsService.addActionsVideoById(id_action, formData);
				}
			}
		}

		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteActionsById = createAsyncThunk('actions/deleteActionsById', async (actionsId, { rejectWithValue }) => {
	try {
		const response = await ActionsService.deleteActionsById(actionsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteDraftsActionsById = createAsyncThunk('actions/deleteDraftsActionsById', async (actionsId, { rejectWithValue }) => {
	try {
		const response = await ActionsService.deleteDraftsActionsById(actionsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const actionsSlice = createSlice({
	name: 'actions',
	initialState,
	reducers: {
		resetMessageActions: (state) => {
			state.messageActions = ''
		},
		
		resetActionsCreatedId: (state) => {
			state.actionsCreatedId = ''
		},
		resetActionsUpdatedId: (state) => {
			state.actionsUpdatedId = ''
		},
		resetActionsPublished: (state) => {
			state.actionsPublished = false
		},
		resetActionsDeleted: (state) => {
			state.actionsDeleted = false
		},
		
		resetActionsDetails: (state) => {
			state.actionsDetails = []
		},
	},
    extraReducers: (builder) => {
		builder
			// createDraftsActions
			.addCase(createDraftsActions.pending, (state) => {
				state.loadingActionsDetails = true
			})
			.addCase(createDraftsActions.fulfilled, (state, action) => {
				state.loadingActionsDetails = false
				if (action.payload?.id_action_maitre) {
					state.messageActions = "Action (Mise à jour) créée avec succès"
				} else {
					state.messageActions = "Action (Brouillon) créée avec succès"
				}
				state.actionsCreatedId = action.payload.id
			})
			.addCase(createDraftsActions.rejected, (state, action) => {
				state.loadingActionsDetails = false
			})

			// getActionsList
			.addCase(getActionsList.pending, (state) => {
				state.loadingActionsList = true
			})
			.addCase(getActionsList.fulfilled, (state, action) => {
				state.loadingActionsList = false
				state.listActionsApi = action.payload
			})
			.addCase(getActionsList.rejected, (state, action) => {
				state.loadingActionsList = false
				state.messageActions = action.payload.message
			})
			
			// getDraftsActionsList
			.addCase(getDraftsActionsList.pending, (state) => {
				state.loadingDraftsActionsList = true
			})
			.addCase(getDraftsActionsList.fulfilled, (state, action) => {
				state.loadingDraftsActionsList = false
				state.listDraftsActionsApi = action.payload
			})
			.addCase(getDraftsActionsList.rejected, (state, action) => {
				state.loadingDraftsActionsList = false
				state.messageActions = action.payload.message
			})

			// getJobsList
			.addCase(getJobsList.pending, (state) => {
				state.loadingJobsList = true
			})
			.addCase(getJobsList.fulfilled, (state, action) => {
				state.loadingJobsList = false
				state.listJobsApi = action.payload
			})
			.addCase(getJobsList.rejected, (state, action) => {
				state.loadingJobsList = false
				state.messageActions = action.payload.message
			})
			
			// publishDraftsActionsById
			.addCase(publishDraftsActionsById.pending, (state) => {
				//
			})
			.addCase(publishDraftsActionsById.fulfilled, (state, action) => {
				state.messageActions = "Action publiée avec succès"
				state.actionsPublished = true
			})
			.addCase(publishDraftsActionsById.rejected, (state, action) => {
				state.messageActions = action.payload.message
			})

			// getActionsById
			.addCase(getActionsById.pending, (state) => {
				state.loadingActionsDetails = true
			})
			.addCase(getActionsById.fulfilled, (state, action) => {
				state.loadingActionsDetails = false
				state.actionsDetails = action.payload
			})
			.addCase(getActionsById.rejected, (state, action) => {
				state.loadingActionsDetails = false
				state.messageActions = action.payload.message
			})
			
			// getDraftsActionsById
			.addCase(getDraftsActionsById.pending, (state) => {
				state.loadingActionsDetails = true
			})
			.addCase(getDraftsActionsById.fulfilled, (state, action) => {
				state.loadingActionsDetails = false
				state.actionsDetails = action.payload
			})
			.addCase(getDraftsActionsById.rejected, (state, action) => {
				state.loadingActionsDetails = false
				state.messageActions = action.payload.message
			})

			// updateDraftsActionsById
			.addCase(updateDraftsActionsById.pending, (state) => {
				state.loadingActionsDetails = true
			})
			.addCase(updateDraftsActionsById.fulfilled, (state, action) => {
				state.loadingActionsDetails = false
				if (action.payload?.id_action_maitre) {
					state.messageActions = "Action (Mise à jour) modifiée avec succès"
				} else {
					state.messageActions = "Action (Brouillon) modifiée avec succès"
				}
				state.actionsUpdatedId = action.payload.id
			})
			.addCase(updateDraftsActionsById.rejected, (state, action) => {
				state.loadingActionsDetails = false
			})

			// deleteActionsById
			.addCase(deleteActionsById.pending, (state) => {
				//
			})
			.addCase(deleteActionsById.fulfilled, (state, action) => {
				state.messageActions = "Action (En prod) supprimée avec succès"
				state.actionsDeleted = true
			})
			.addCase(deleteActionsById.rejected, (state, action) => {
				state.messageActions = action.payload.message
			})
			
			// deleteDraftsActionsById
			.addCase(deleteDraftsActionsById.pending, (state) => {
				//
			})
			.addCase(deleteDraftsActionsById.fulfilled, (state, action) => {
				state.messageActions = "Action (Brouillon/Mise à jour) supprimée avec succès"
				state.actionsDeleted = true
			})
			.addCase(deleteDraftsActionsById.rejected, (state, action) => {
				state.messageActions = action.payload.message
			})
	},
})

export const { resetMessageActions, resetActionsCreatedId, resetActionsUpdatedId, resetActionsPublished, resetActionsDeleted, resetActionsDetails } = actionsSlice.actions

export default actionsSlice.reducer