import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TravauxService from 'services/TravauxService';

export const initialState = {
	loadingTravauxDetails: false,
	loadingTravauxList: false,
	loadingTravauxListWithQuestionsReponses: false,
	
	messageTravaux: '',
	
	travauxCreatedId: '',
	travauxUpdatedId: '',
	travauxDeleted: false,
	
	travauxDetails: [],
	listTravauxApi: [],
	listTravauxWithQuestionsReponsesApi: [],
}

// Initial Travaux creation
export const createTravaux = createAsyncThunk('travaux/createTravaux', async (travauxData, { rejectWithValue }) => {
	const { libelle, sous_domaine_picto, list_thematiques } = travauxData
	try {
		// Create Question Racine
		const responseQuestionRacine = await TravauxService.createQuestion({libelle: list_thematiques[0].question});
		if (list_thematiques[0].children && list_thematiques[0].children.length > 0) {
			// Create all children
			await addQuestionsReponses(responseQuestionRacine.data.id, list_thematiques[0].children);
		}

		const response = await TravauxService.createTravaux({libelle, id_question_racine: responseQuestionRacine.data.id })
		if (sous_domaine_picto) {
			const tempId = response.data.id
			const formData = new FormData();
			formData.append('sous_domaine_picto', sous_domaine_picto);
			await TravauxService.addTravauxPictoById(tempId, formData)
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// Recursive function for the creation of children
const addQuestionsReponses = async (parentId, children) => {
	for (const child of children) {
		const { title, question, children: nestedChildren, ordre } = child;
		let nestedParent;
  
		if (question) {
			const responseQuestionFille = await TravauxService.createQuestion({ libelle: question });
			nestedParent = await TravauxService.createQuestionReponse(parentId, { libelle: title, id_question_fille: responseQuestionFille ? responseQuestionFille.data.id: null, ordre });
			if (question && nestedChildren && nestedChildren.length > 0) {
				await addQuestionsReponses(responseQuestionFille.data.id, nestedChildren);
			}
		} else {
		nestedParent = await TravauxService.createQuestionReponse(parentId, { libelle: title, ordre });
	  }
	}
};

export const getTravauxList = createAsyncThunk('travaux/getTravauxList', async (_, { rejectWithValue }) => {
	try {
		const response = await TravauxService.getTravauxList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getTravauxWithQuestionsReponsesList = createAsyncThunk( 'travaux/getTravauxWithQuestionsReponsesList', async (_, { rejectWithValue }) => {
	try {
		const responseTravauxWithQuestionsReponses = await TravauxService.getTravauxWithQuestionsReponsesList();
		return responseTravauxWithQuestionsReponses.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error');
	}
})  

// ID
export const getTravauxById = createAsyncThunk('travaux/getTravauxById', async (travauxId, { rejectWithValue }) => {
	try {
		const response = await TravauxService.getTravauxById(travauxId);
		const responseBis = await TravauxService.getTravauxQuestionsReponsesList(travauxId)
		return {
			sous_domaine: response.data,
			list_thematiques: responseBis.data,
		};
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateTravauxById = createAsyncThunk('travaux/updateTravauxById', async (travauxData, { rejectWithValue }) => {
	try {
		const { id_sous_domaine, libelle, sous_domaine_picto, added_thematiques, updated_thematiques } = travauxData
		const response = await TravauxService.updateTravauxById(id_sous_domaine, {libelle});
		if (sous_domaine_picto) {
			const formData = new FormData();
			formData.append('sous_domaine_picto', sous_domaine_picto);
			await TravauxService.deleteTravauxPictoById(id_sous_domaine)
			await TravauxService.addTravauxPictoById(id_sous_domaine, formData)
		}
		
		async function executeUpdateSequence() {
			let updatedReponsesWithQuestionsFilles = [];
			
			// Update all the necessary thematiques
			if (updated_thematiques && updated_thematiques.length > 0) {
				for (const thematique of updated_thematiques) {
					if (thematique?.question) {
						if (thematique.key == 'parentNode') {
							await TravauxService.updateQuestion(thematique.id_question, {libelle: thematique.question});
						} else if (thematique.id_question_fille) {
							await TravauxService.updateQuestion(thematique.id_question_fille, {libelle: thematique.question});
						} else {
							const newQuestion = await TravauxService.createQuestion({libelle: thematique.question});
							const updatedQuestionReponse = await TravauxService.updateQuestionReponse(thematique.id_question, thematique.key, {id_question_fille: newQuestion.data.id, ordre: thematique.ordre})
							const updatedQuestionReponseWithFille = { ...updatedQuestionReponse.data, id_question_fille: newQuestion.data.id };
							updatedReponsesWithQuestionsFilles.push(updatedQuestionReponseWithFille);
						}
					} else if (!thematique?.question && thematique?.id_question_fille && thematique.key != "parentNode") {
						await TravauxService.deleteQuestion(thematique.id_question_fille);
					}
				
					if (thematique.key != "parentNode") {
						await TravauxService.updateQuestionReponse(thematique.id_question, thematique.key, {libelle: thematique.title, ordre: thematique.ordre});
					}
				}
			}
			// Add all the necessary thematiques
			if (added_thematiques && added_thematiques.length > 0) {
				for (const thematique of added_thematiques) {
					var newQuestion;
					var newReponse;
					if (thematique?.question) {
						newQuestion = await TravauxService.createQuestion({libelle: thematique.question});
					}
					if (thematique.key != "parentNode") {
						if (thematique.id_question) {
							let updatedQuestionReponseId = null;
							// Recover the id of a newly created question (if there was one) by update block
							for ( const updatedReponseWithQuestionFille of updatedReponsesWithQuestionsFilles) {
								if (updatedReponseWithQuestionFille.id == thematique.parent) {
									updatedQuestionReponseId = updatedReponseWithQuestionFille.id_question_fille;
									break;
								}
							}
							newReponse = await TravauxService.createQuestionReponse(updatedQuestionReponseId ? updatedQuestionReponseId: thematique.id_question, {libelle: thematique.title, id_question_fille: newQuestion ? newQuestion.data.id: null, ordre: thematique.ordre});
						} else {
							newReponse = await TravauxService.createQuestionReponse(thematique.parent, {libelle: thematique.title, id_question_fille: newQuestion ? newQuestion.data.id: null, ordre: thematique.ordre});
						}
					}

					if (thematique.key == "parentNode") {
						await TravauxService.updateTravauxById(id_sous_domaine, {id_question_racine: newQuestion.data.id});
					}
					if (thematique?.children.length > 0) {
						await addQuestionsReponses(newQuestion.data.id, thematique.children) 
					}
					
				}
			}
		}
		await executeUpdateSequence();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
});

export const deleteTravauxQuestionReponseById = createAsyncThunk('travaux/deleteTravauxQuestionReponseById', async (travauxQuestionReponseData, { rejectWithValue }) => {
	try {
		const { travauxQuestionId , travauxReponseId } = travauxQuestionReponseData;
		var response;
		if ( travauxReponseId != 'parentNode') {
			response =  await TravauxService.deleteQuestionReponse(travauxQuestionId, travauxReponseId);
		} else {
			response = await TravauxService.deleteQuestion(travauxQuestionId);
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error');
	}
});

// Delete Travaux
export const deleteTravauxById = createAsyncThunk('travaux/deleteTravauxById', async (travauxId, { rejectWithValue }) => {
	try {
		const response = await TravauxService.deleteTravauxById(travauxId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error');
	}
});

export const travauxSlice = createSlice({
	name: 'travaux',
	initialState,
	reducers: {
		resetMessageTravaux: (state) => {
			state.messageTravaux = ''
		},
		
		resetTravauxCreatedId: (state) => {
			state.travauxCreatedId = ''
		},
		resetTravauxUpdatedId: (state) => {
			state.travauxUpdatedId = ''
		},
		resetTravauxDeleted: (state) => {
			state.travauxDeleted = false
		},
		
		resetTravauxDetails: (state) => {
			state.travauxDetails = []
		},
	},
    extraReducers: (builder) => {
		builder
		// createTravaux
		.addCase(createTravaux.pending, (state) => {
			state.loadingTravauxDetails = true
		})
		.addCase(createTravaux.fulfilled, (state, action) => {
			state.loadingTravauxDetails = false
			state.messageTravaux = "Travaux créés avec succès"
			state.travauxCreatedId = action.payload.id
		})
		.addCase(createTravaux.rejected, (state, action) => {
			state.loadingTravauxDetails = false
		})

		// getTravauxList
		.addCase(getTravauxList.pending, (state) => {
			state.loadingTravauxList = true
		})
		.addCase(getTravauxList.fulfilled, (state, action) => {
			state.loadingTravauxList = false
			state.listTravauxApi = action.payload
		})
		.addCase(getTravauxList.rejected, (state, action) => {
			state.loadingTravauxList = false
			state.message = action.payload.message
		})

		// getTravauxWithQuestionsReponsesList
		.addCase(getTravauxWithQuestionsReponsesList.pending, (state) => {
			state.loadingTravauxListWithQuestionsReponses = true
		})
		.addCase(getTravauxWithQuestionsReponsesList.fulfilled, (state, action) => {
			state.loadingTravauxListWithQuestionsReponses = false
			state.listTravauxWithQuestionsReponsesApi = action.payload
		})
		.addCase(getTravauxWithQuestionsReponsesList.rejected, (state, action) => {
			state.loadingTravauxListWithQuestionsReponses = false
			state.messageTravaux = action.payload.message
		})

		// getTravauxById
		.addCase(getTravauxById.pending, (state) => {
			state.loadingTravauxDetails = true
		})
		.addCase(getTravauxById.fulfilled, (state, action) => {
			state.loadingTravauxDetails = false
			state.travauxDetails = action.payload
		})
		.addCase(getTravauxById.rejected, (state, action) => {
			state.loadingTravauxDetails = false
			state.messageTravaux = action.payload.message
		})

		// updateTravauxById
		.addCase(updateTravauxById.pending, (state) => {
			state.loadingTravauxDetails = true
		})
		.addCase(updateTravauxById.fulfilled, (state, action) => {
			state.loadingTravauxDetails = false
			state.messageTravaux = "Travaux modifiés avec succès"
			state.travauxUpdatedId = action.payload.id
		})
		.addCase(updateTravauxById.rejected, (state, action) => {
			state.loadingTravauxDetails = false
		})
		
		// deleteTravauxQuestionReponseById
		.addCase(deleteTravauxQuestionReponseById.pending, (state) => {
			state.loadingTravauxDetails = true
		})
		.addCase(deleteTravauxQuestionReponseById.fulfilled, (state, action) => {
			state.loadingTravauxDetails = false
			state.messageTravaux = "Travaux modifiés avec succès"
		})
		.addCase(deleteTravauxQuestionReponseById.rejected, (state, action) => {
			state.loadingTravauxDetails = false
		})

		// deleteTravauxById
		.addCase(deleteTravauxById.pending, (state) => {
			// 
		})
		.addCase(deleteTravauxById.fulfilled, (state, action) => {
			state.messageTravaux = "Travaux supprimés avec succès"
			state.travauxDeleted = true
		})
		.addCase(deleteTravauxById.rejected, (state, action) => {
			state.messageTravaux = action.payload.message
		})
	},
})

export const { resetMessageTravaux, resetTravauxCreatedId, resetTravauxUpdatedId, resetTravauxDeleted, resetTravauxDetails } = travauxSlice.actions

export default travauxSlice.reducer