import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BeabasService from 'services/BeabasService';

export const initialState = {
	loadingBeabasDetails: false,
	loadingBeabasList: false,
	loadingDraftsBeabasList: false,
	loadingJobsList: false,
	
	messageBeabas: '',
	
	beabasCreatedId: '',
	beabasUpdatedId: '',
	beabasPublished: false,
	beabasDeleted: false,
	
	beabasDetails: [],
	listBeabasApi: [],
	listDraftsBeabasApi: [],
	listJobsListApi: [],
}

export const createDraftsBeabas = createAsyncThunk('beabas/createDraftsBeabas', async (draftsBeabasData, { rejectWithValue }) => {
	const { id_beaba, libelle, duration, id_metier, beaba_thumbnail, list_videos, } = draftsBeabasData
	try {
		var response;
		if (id_beaba) {
			response = await BeabasService.createDraftsBeabas({ libelle, duration, id_metier, id_beaba_maitre: id_beaba })
		} else {
			response = await BeabasService.createDraftsBeabas({ libelle, duration, id_metier })

			if (beaba_thumbnail) {
				const tempId = response.data.id
				const formData = new FormData();
				formData.append('brouillon_beaba_photo', beaba_thumbnail);
				await BeabasService.addBeabasThumbnailById(tempId, formData)
			}
			
			if (list_videos && list_videos.length > 0) {
				for (const video of list_videos) {
					const tempId = response.data.id;
					const formData = new FormData();
					formData.append('brouillon_beaba_video', video); 
					formData.append('file_video_angle_code', video.videoAngle); 
					await BeabasService.addBeabasVideoById(tempId, formData);
				}
			}
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getBeabasList = createAsyncThunk('beabas/getBeabasList', async (_, { rejectWithValue }) => {
	try {
		const response = await BeabasService.getBeabasList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDraftsBeabasList = createAsyncThunk('beabas/getDraftsBeabasList', async (_, { rejectWithValue }) => {
	try {
		const response = await BeabasService.getDraftsBeabasList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getJobsList = createAsyncThunk('beabas/getJobsList', async (_, { rejectWithValue }) => {
	try {
		const response = await BeabasService.getJobsList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// ID
export const publishDraftsBeabasById = createAsyncThunk('beabas/publishDraftsBeabasById',async (beabasId, { rejectWithValue }) => {
	try {
		const response = await BeabasService.publishDraftsBeabasById(beabasId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getBeabasById = createAsyncThunk('beabas/getBeabasById', async (beabasId, { rejectWithValue }) => {
	try {
		const response = await BeabasService.getBeabasById(beabasId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDraftsBeabasById = createAsyncThunk('beabas/getDraftsBeabasById', async (draftsBeabasId, { rejectWithValue }) => {
	try {
		const response = await BeabasService.getDraftsBeabasById(draftsBeabasId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateDraftsBeabasById = createAsyncThunk('beabas/updateDraftsBeabasById', async (draftsBeabasData, { rejectWithValue }) => {
	const { id_beaba, libelle, duration, id_metier, beaba_thumbnail, list_videos, list_videos_deleted } = draftsBeabasData
	try {
		const response = await BeabasService.updateDraftsBeabasById(id_beaba, { libelle, duration, id_metier })
		
		if (beaba_thumbnail) {
			const formData = new FormData();
			formData.append('brouillon_beaba_photo', beaba_thumbnail);
			await BeabasService.deleteBeabasThumbnailById(id_beaba);
			await BeabasService.addBeabasThumbnailById(id_beaba, formData);
		}
		
		if (list_videos_deleted && list_videos_deleted.length > 0) {
			for (const video_deleted of list_videos_deleted) {
				await BeabasService.deleteBeabasVideoById(id_beaba, {id_file: video_deleted.uid});
			}
		}
		
		if (list_videos && list_videos.length > 0) {
			for (const video of list_videos) {
				const fileName = video.name;
				const regex = /videoApi\d+/; 
				if (!(regex.test(fileName))) {
					const formData = new FormData();
					formData.append('brouillon_beaba_video', video); 
					formData.append('file_video_angle_code', video.videoAngle); 
					await BeabasService.addBeabasVideoById(id_beaba, formData);
				}
			}
		}

		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteBeabasById = createAsyncThunk('beabas/deleteBeabasById', async (beabasId, { rejectWithValue }) => {
	try {
		const response = await BeabasService.deleteBeabasById(beabasId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteDraftsBeabasById = createAsyncThunk('beabas/deleteDraftsBeabasById', async (beabasId, { rejectWithValue }) => {
	try {
		const response = await BeabasService.deleteDraftsBeabasById(beabasId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const beabasSlice = createSlice({
	name: 'beabas',
	initialState,
	reducers: {
		resetMessageBeabas: (state) => {
			state.messageBeabas = ''
		},
		
		resetBeabasCreatedId: (state) => {
			state.beabasCreatedId = ''
		},
		resetBeabasUpdatedId: (state) => {
			state.beabasUpdatedId = ''
		},
		resetBeabasPublished: (state) => {
			state.beabasPublished = false
		},
		resetBeabasDeleted: (state) => {
			state.beabasDeleted = false
		},
		
		resetBeabasDetails: (state) => {
			state.beabasDetails = []
		},
	},
    extraReducers: (builder) => {
		builder
			// createDraftsBeabas
			.addCase(createDraftsBeabas.pending, (state) => {
				state.loadingBeabasDetails = true
			})
			.addCase(createDraftsBeabas.fulfilled, (state, beaba) => {
				state.loadingBeabasDetails = false
				if (beaba.payload?.id_beaba_maitre) {
					state.messageBeabas = "Béaba (Mise à jour) créé avec succès"
				} else {
					state.messageBeabas = "Béaba (Brouillon) créé avec succès"
				}
				state.beabasCreatedId = beaba.payload.id
			})
			.addCase(createDraftsBeabas.rejected, (state, beaba) => {
				state.loadingBeabasDetails = false
			})

			// getBeabasList
			.addCase(getBeabasList.pending, (state) => {
				state.loadingBeabasList = true
			})
			.addCase(getBeabasList.fulfilled, (state, beaba) => {
				state.loadingBeabasList = false
				state.listBeabasApi = beaba.payload
			})
			.addCase(getBeabasList.rejected, (state, beaba) => {
				state.loadingBeabasList = false
				state.messageBeabas = beaba.payload.message
			})
			
			// getDraftsBeabasList
			.addCase(getDraftsBeabasList.pending, (state) => {
				state.loadingDraftsBeabasList = true
			})
			.addCase(getDraftsBeabasList.fulfilled, (state, beaba) => {
				state.loadingDraftsBeabasList = false
				state.listDraftsBeabasApi = beaba.payload
			})
			.addCase(getDraftsBeabasList.rejected, (state, beaba) => {
				state.loadingDraftsBeabasList = false
				state.messageBeabas = beaba.payload.message
			})

			// getJobsList
			.addCase(getJobsList.pending, (state) => {
				state.loadingJobsList = true
			})
			.addCase(getJobsList.fulfilled, (state, beaba) => {
				state.loadingJobsList = false
				state.listJobsApi = beaba.payload
			})
			.addCase(getJobsList.rejected, (state, beaba) => {
				state.loadingJobsList = false
				state.messageBeabas = beaba.payload.message
			})
			
			// publishDraftsBeabasById
			.addCase(publishDraftsBeabasById.pending, (state) => {
				//
			})
			.addCase(publishDraftsBeabasById.fulfilled, (state, beaba) => {
				state.messageBeabas = "Béaba publié avec succès"
				state.beabasPublished = true
			})
			.addCase(publishDraftsBeabasById.rejected, (state, beaba) => {
				state.messageBeabas = beaba.payload.message
			})

			// getBeabasById
			.addCase(getBeabasById.pending, (state) => {
				state.loadingBeabasDetails = true
			})
			.addCase(getBeabasById.fulfilled, (state, beaba) => {
				state.loadingBeabasDetails = false
				state.beabasDetails = beaba.payload
			})
			.addCase(getBeabasById.rejected, (state, beaba) => {
				state.loadingBeabasDetails = false
				state.messageBeabas = beaba.payload.message
			})
			
			// getDraftsBeabasById
			.addCase(getDraftsBeabasById.pending, (state) => {
				state.loadingBeabasDetails = true
			})
			.addCase(getDraftsBeabasById.fulfilled, (state, beaba) => {
				state.loadingBeabasDetails = false
				state.beabasDetails = beaba.payload
			})
			.addCase(getDraftsBeabasById.rejected, (state, beaba) => {
				state.loadingBeabasDetails = false
				state.messageBeabas = beaba.payload.message
			})

			// updateDraftsBeabasById
			.addCase(updateDraftsBeabasById.pending, (state) => {
				state.loadingBeabasDetails = true
			})
			.addCase(updateDraftsBeabasById.fulfilled, (state, beaba) => {
				state.loadingBeabasDetails = false
				if (beaba.payload?.id_beaba_maitre) {
					state.messageBeabas = "Béaba (Mise à jour) modifié avec succès"
				} else {
					state.messageBeabas = "Béaba (Brouillon) modifié avec succès"
				}
				state.beabasUpdatedId = beaba.payload.id
			})
			.addCase(updateDraftsBeabasById.rejected, (state, beaba) => {
				state.loadingBeabasDetails = false
			})

			// deleteBeabasById
			.addCase(deleteBeabasById.pending, (state) => {
				//
			})
			.addCase(deleteBeabasById.fulfilled, (state, beaba) => {
				state.messageBeabas = "Béaba (En prod) supprimé avec succès"
				state.beabasDeleted = true
			})
			.addCase(deleteBeabasById.rejected, (state, beaba) => {
				state.messageBeabas = beaba.payload.message
			})
			
			// deleteDraftsBeabasById
			.addCase(deleteDraftsBeabasById.pending, (state) => {
				//
			})
			.addCase(deleteDraftsBeabasById.fulfilled, (state, beaba) => {
				state.messageBeabas = "Béaba (Brouillon) supprimé avec succès"
				state.beabasDeleted = true
			})
			.addCase(deleteDraftsBeabasById.rejected, (state, beaba) => {
				state.messageBeabas = beaba.payload.message
			})
	},
})

export const { resetMessageBeabas, resetBeabasCreatedId, resetBeabasUpdatedId, resetBeabasPublished, resetBeabasDeleted, resetBeabasDetails } = beabasSlice.actions

export default beabasSlice.reducer