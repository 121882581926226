import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN, USERNAME } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [400, 401, 403]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

let lastError422Timestamp = 0;

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;
	
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = 'Bearer' + jwtToken
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response.status)) {
		const currentTime = new Date().getTime();
		const timeDifferenceInSeconds = (currentTime - lastError422Timestamp) / 1000; 

		// Notifications anti-spam 3 seconds delay
		if (timeDifferenceInSeconds > 3) {
			notificationParam.message = "Erreur d'authentification"
			notificationParam.description = "Essayez à nouveau"
			localStorage.removeItem(AUTH_TOKEN)
			localStorage.removeItem(USERNAME)
			store.dispatch(signOutSuccess())
			notification.error(notificationParam)
			lastError422Timestamp = currentTime;
		}
	} else {

		if (error.response.status === 404) {
			notificationParam.message = 'Not Found'
		}
		
		if (error.response.status === 413) {
			notificationParam.message = 'Request Entity Too Large'
		}

		if (error.response.status === 422) {
			// Return an empty array if 422 is allowed
			if (error.config?.allowError422) {
				return Promise.resolve([]);
			} else {
				notificationParam.message = 'Unprocessable Content'
			}
		}
		
		if (error.response.status === 429) {
			notificationParam.message = 'Too Many Requests'
		}

		if (error.response.status === 500) {
			notificationParam.message = 'Internal Server Error'
		}
		
		if (error.response.status === 508) {
			notificationParam.message = 'Time Out'
		}

		notification.error(notificationParam)

		return Promise.reject(error);
	}
});

export default service