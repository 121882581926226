import fetch from "auth/FetchInterceptor";

const EtapesService = {};

EtapesService.createDraftsEtapes = function (etapesData) {
    return fetch({
        url: "/brouillons/etapes",
        method: "post",
        data: etapesData,
    });
};

EtapesService.getEtapesTypesList = function () {
    return fetch({
        url: "/etapes/types",
        method: "get",
    });
};

// ID
EtapesService.getEtapesById = function (etapesId) {
    return fetch({
        url: "/etapes/" + etapesId,
        method: "get",
    });
};

EtapesService.getDraftsEtapesById = function (draftsEtapesId) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId,
        method: "get",
    });
};

EtapesService.getEtapesListById = function (baseUrl, elementsId) {
    return fetch({
        url: "/" + baseUrl + "/" + elementsId + "/etapes",
        method: "get",
    });
};

EtapesService.getDraftsEtapesListById = function (baseUrl, draftsId) {
    return fetch({
        url: "/brouillons/" + baseUrl + "/" + draftsId + "/etapes",
        method: "get",
    });
};

EtapesService.updateDraftsEtapesById = function (draftEtapesId, draftEtapesData) {
    return fetch({
        url: "/brouillons/etapes/" + draftEtapesId,
        method: "put",
        data: draftEtapesData,
    });
};

EtapesService.deleteEtapesById = function (etapesId) {
    return fetch({
        url: "/etapes/" + etapesId,
        method: "delete",
    });
};

EtapesService.deleteDraftsEtapesById = function (draftsEtapesId, elementsData) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId,
        method: "delete",
        data: elementsData,
    });
};

// Oms
EtapesService.addDraftsEtapesOmsById = function (draftsEtapesId, omsId) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId + "/equipements/" + omsId,
        method: "post",
    });
};
EtapesService.deleteDraftsEtapesOmsById = function (draftsEtapesId, omsId) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId + "/equipements/" + omsId,
        method: "delete",
    });
};

// Beabas
EtapesService.addDraftsEtapesBeabasById = function (draftsEtapesId, beabasId, draftEtapesBeabasData) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId + "/beabas/" + beabasId,
        method: "post",
        data: draftEtapesBeabasData
    });
};

EtapesService.updateDraftsEtapesBeabasById = function (draftsEtapesId, beabasId, draftEtapesBeabasData) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId + "/beabas/" + beabasId,
        method: "put",
        data: draftEtapesBeabasData
    });
};

EtapesService.deleteDraftsEtapesBeabasById = function (draftsEtapesId, beabasId) {
    return fetch({
        url: "/brouillons/etapes/" + draftsEtapesId + "/beabas/" + beabasId,
        method: "delete",
    });
};

export default EtapesService;
