import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('pages/auth-pages/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('pages/auth-pages/forgot-password')),
    }
]

export const protectedRoutes = [
    
    // Dasboard
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('pages/app-pages/dashboard')),
    },
    
    // Apprenants
    {
        key: 'apprenants',
        path: `${APP_PREFIX_PATH}/apprenants`,
        component: React.lazy(() => import('pages/app-pages/dashboard')),
    },

    // Actions
    {
        key: 'actions',
        path: `${APP_PREFIX_PATH}/actions`,
        component: React.lazy(() => import('pages/app-pages/actions')),
    },
    {
        key: 'actions.create',
        path: `${APP_PREFIX_PATH}/createActions`,
        component: React.lazy(() => import('pages/app-pages/actions/EditActions')),
        meta: {
            update: 0,
        }
    },
    {
        key: 'actions.update',
        path: `${APP_PREFIX_PATH}/updateActions`,
        component: React.lazy(() => import('pages/app-pages/actions/EditActions')),
        meta: {
            update: 1,
        }
    },
    
    // Béabas
    {
        key: 'beabas',
        path: `${APP_PREFIX_PATH}/beabas`,
        component: React.lazy(() => import('pages/app-pages/beabas')),
    },
    {
        key: 'beabas.create',
        path: `${APP_PREFIX_PATH}/createBeabas`,
        component: React.lazy(() => import('pages/app-pages/beabas/EditBeabas')),
        meta: {
            update: 0,
        }
    },
    {
        key: 'beabas.update',
        path: `${APP_PREFIX_PATH}/updateBeabas`,
        component: React.lazy(() => import('pages/app-pages/beabas/EditBeabas')),
        meta: {
            update: 1,
        }
    },

    // Outils, Matériaux, Sécurité
    {
        key: 'oms',
        path: `${APP_PREFIX_PATH}/oms`,
        component: React.lazy(() => import('pages/app-pages/oms')),
    },
    {
        key: 'oms.create',
        path: `${APP_PREFIX_PATH}/createOms`,
        component: React.lazy(() => import('pages/app-pages/oms/EditOms')),
        meta: {
            update: 0,
        }
    },
    {
        key: 'oms.update',
        path: `${APP_PREFIX_PATH}/updateOms`,
        component: React.lazy(() => import('pages/app-pages/oms/EditOms')),
        meta: {
            update: 1,
        }
    },

    // Travaux
    {
        key: 'travaux',
        path: `${APP_PREFIX_PATH}/travaux`,
        component: React.lazy(() => import('pages/app-pages/travaux')),
    },
    {
        key: 'travaux.create',
        path: `${APP_PREFIX_PATH}/createTravaux`,
        component: React.lazy(() => import('pages/app-pages/travaux/EditTravaux')),
        meta: {
            update: 0,
        }
    },
    {
        key: 'travaux.update',
        path: `${APP_PREFIX_PATH}/updateTravaux`,
        component: React.lazy(() => import('pages/app-pages/travaux/EditTravaux')),
        meta: {
            update: 1,
        }
    }
]