import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import OmsService from 'services/OmsService';

export const initialState = {
	loadingOmsDetails: false,
	loadingOmsList: false,
	loadingOmsTypesList: false,
	
	messageOms: '',
	
	omsCreatedId: '',
	omsUpdatedId: '',
	omsDeleted: false,
	
	omsDetails: [],
	listOmsApi: [],
	listOmsTypesApi: [],
}

export const createOms = createAsyncThunk('oms/createOms',async (omsData, { rejectWithValue }) => {
	const { libelle, id_equipement_type, equipement_picto } = omsData
	try {
		const response = await OmsService.createOms({ libelle, id_equipement_type })
		if (equipement_picto) {
			const tempId = response.data.id
			const formData = new FormData();
			formData.append('equipement_picto', equipement_picto);
			await OmsService.addOmsPictoById(tempId, formData)
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOmsList = createAsyncThunk('oms/getOmsList', async (_, { rejectWithValue }) => {
	try {
		const response = await OmsService.getOmsList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOmsTypesList = createAsyncThunk('oms/getOmsTypesList', async (_, { rejectWithValue }) => {
	try {
		const response = await OmsService.getOmsTypesList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// ID
export const getOmsById = createAsyncThunk('oms/getOmsById', async (omsId, { rejectWithValue }) => {
	try {
		const response = await OmsService.getOmsById(omsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOmsById = createAsyncThunk('oms/updateOmsById', async (omsData, { rejectWithValue }) => {
	try {
		const { id_equipement, libelle, id_equipement_type, equipement_picto } = omsData
		const response = await OmsService.updateOmsById(id_equipement, { libelle, id_equipement_type });
		if (equipement_picto) {
			const formData = new FormData();
			formData.append('equipement_picto', equipement_picto);
			await OmsService.deleteOmsPictoById(id_equipement);
			await OmsService.addOmsPictoById(id_equipement, formData);
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOmsById = createAsyncThunk('oms/deleteOmsById', async (omsId, { rejectWithValue }) => {
	try {
		const response = await OmsService.deleteOmsById(omsId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const omsSlice = createSlice({
	name: 'oms',
	initialState,
	reducers: {
		resetMessageOms: (state) => {
			state.messageOms = ''
		},
		
		resetOmsCreatedId: (state) => {
			state.omsCreatedId = ''
		},
		resetOmsUpdatedId: (state) => {
			state.omsUpdatedId = ''
		},
		resetOmsDeleted: (state) => {
			state.omsDeleted = false
		},
		
		resetOmsDetails: (state) => {
			state.omsDetails = []
		}
	},
    extraReducers: (builder) => {
		builder
			// createOms
			.addCase(createOms.pending, (state) => {
				state.loadingOmsDetails = true
			})
			.addCase(createOms.fulfilled, (state, action) => {
				state.loadingOmsDetails = false
				state.messageOms = "Outil, Matériaux, Sécurité crée avec succès"
				state.omsCreatedId = action.payload.id
			})
			.addCase(createOms.rejected, (state, action) => {
				state.loadingOmsDetails = false
			})

			// getOmsList
			.addCase(getOmsList.pending, (state) => {
				state.loadingOmsList = true
			})
			.addCase(getOmsList.fulfilled, (state, action) => {
				state.loadingOmsList = false
				state.listOmsApi = action.payload
			})
			.addCase(getOmsList.rejected, (state, action) => {
				state.loadingOmsList = false
				state.messageOms = action.payload.message
			})

			// getOmsTypesList
			.addCase(getOmsTypesList.pending, (state) => {
				state.loadingOmsTypesList = true
			})
			.addCase(getOmsTypesList.fulfilled, (state, action) => {
				state.loadingOmsTypesList = false
				state.listOmsTypesApi = action.payload
			})
			.addCase(getOmsTypesList.rejected, (state, action) => {
				state.loadingOmsTypesList = false
				state.messageOms = action.payload.message
			})

			// getOmsById
			.addCase(getOmsById.pending, (state) => {
				state.loadingOmsDetails = true
			})
			.addCase(getOmsById.fulfilled, (state, action) => {
				state.loadingOmsDetails = false
				state.omsDetails = action.payload
			})
			.addCase(getOmsById.rejected, (state, action) => {
				state.loadingOmsDetails = false
				state.messageOms = action.payload.message
			})

			// updateOmsById
			.addCase(updateOmsById.pending, (state) => {
				state.loadingOmsDetails = true
			})
			.addCase(updateOmsById.fulfilled, (state, action) => {
				state.loadingOmsDetails = false
				state.messageOms = "Outil, Matériaux, Sécurité modifiée avec succès"
				state.omsUpdatedId = action.payload.id
			})
			.addCase(updateOmsById.rejected, (state, action) => {
				state.loadingOmsDetails = false
			})

			// deleteOmsById
			.addCase(deleteOmsById.pending, (state) => {
				// 
			})
			.addCase(deleteOmsById.fulfilled, (state, action) => {
				state.messageOms = "Outil, Matériaux, Sécurité supprimée avec succès"
				state.omsDeleted = true
			})
			.addCase(deleteOmsById.rejected, (state, action) => {
				state.messageOms = action.payload.message
			})
	},
})

export const { resetMessageOms, resetOmsCreatedId, resetOmsUpdatedId, resetOmsDeleted, resetOmsDetails } = omsSlice.actions

export default omsSlice.reducer