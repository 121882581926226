import fetch from "auth/FetchInterceptor";

const BeabasService = {};

BeabasService.createDraftsBeabas = function (beabasData) {
    return fetch({
        url: "/brouillons/beabas",
        method: "post",
        data: beabasData,
    });
};

BeabasService.getBeabasList = function () {
    return fetch({
        url: "/beabas",
        method: "get",
        allowError422: true
    });
};

BeabasService.getDraftsBeabasList = function () {
    return fetch({
        url: "/brouillons/beabas",
        method: "get",
        allowError422: true
    });
};

BeabasService.getJobsList = function () {
    return fetch({
        url: "/metiers",
        method: "get",
    });
};

// ID
BeabasService.publishDraftsBeabasById = function (draftsBeabasId) {
    return fetch({
        url: "/brouillons/beabas/" + draftsBeabasId + "/publish",
        method: "post"
    });
};

BeabasService.getBeabasById = function (beabasId) {
    return fetch({
        url: "/beabas/" + beabasId,
        method: "get",
    });
};

BeabasService.getDraftsBeabasById = function (draftsBeabasId) {
    return fetch({
        url: "/brouillons/beabas/" + draftsBeabasId,
        method: "get",
    });
};

BeabasService.updateDraftsBeabasById = function (draftsBeabasId, draftsBeabasData) {
    return fetch({
        url: "/brouillons/beabas/" + draftsBeabasId,
        method: "put",
        data: draftsBeabasData,
    });
};

BeabasService.deleteBeabasById = function (beabasId) {
    return fetch({
        url: "/beabas/" + beabasId,
        method: "delete",
    });
};

BeabasService.deleteDraftsBeabasById = function (beabasId) {
    return fetch({
        url: "/brouillons/beabas/" + beabasId,
        method: "delete",
    });
};

// Images
BeabasService.addBeabasThumbnailById = function (beabasId, beabasData) {
    return fetch({
        url: "/brouillons/beabas/" + beabasId + "/images",
        method: "post",
        data: beabasData,
    });
};

BeabasService.deleteBeabasThumbnailById = function (beabasId) {
    return fetch({
        url: "/brouillons/beabas/" + beabasId + "/images",
        method: "delete",
    });
};

// Vidéos
BeabasService.addBeabasVideoById = function (beabasId, beabasData) {
    return fetch({
        url: "/brouillons/beabas/" + beabasId + "/videos",
        method: "post",
        data: beabasData,
    });
};

BeabasService.deleteBeabasVideoById = function (beabasId, beabasData) {
    return fetch({
        url: "/brouillons/beabas/" + beabasId + "/videos",
        method: "delete",
        data: beabasData,
    });
};

export default BeabasService;