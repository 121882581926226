import fetch from "auth/FetchInterceptor";

const OmsService = {};

OmsService.createOms = function (omsData) {
    return fetch({
        url: "/equipements",
        method: "post",
        data: omsData,
    });
};

OmsService.getOmsList = function () {
    return fetch({
        url: "/equipements",
        method: "get",
        allowError422: true
    });
};

OmsService.getOmsTypesList = function () {
    return fetch({
        url: "/equipements/types",
        method: "get",
    });
};

// ID
OmsService.getOmsById = function (omsId) {
    return fetch({
        url: "/equipements/" + omsId,
        method: "get",
    });
};

OmsService.updateOmsById = function (omsId, omsData) {
    return fetch({
        url: "/equipements/" + omsId,
        method: "put",
        data: omsData,
    });
};

OmsService.deleteOmsById = function (omsId) {
    return fetch({
        url: "/equipements/" + omsId,
        method: "delete",
    });
};

// Images
OmsService.addOmsPictoById = function (omsId, omsData) {
    return fetch({
        url: "/equipements/" + omsId + "/images",
        method: "post",
        data: omsData,
    });
};

OmsService.deleteOmsPictoById = function (omsId) {
    return fetch({
        url: "/equipements/" + omsId + "/images",
        method: "delete",
    });
};

export default OmsService;
