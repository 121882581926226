import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EtapesService from 'services/EtapesService';

export const initialState = {
	loadingEtapesDetails: false,
	loadingEtapesList: false,
	loadingEtapesTypesList: false,
	
	messageEtapes: '',
	
	etapesCreated: false,
	etapesUpdatedId: '',
	etapesDeleted: false,
	
	etapesDetails: [],
	listEtapesApi: [],
	listEtapesTypes: []
}

export const createDraftsEtapes = createAsyncThunk('etapes/createDraftsEtapes', async (draftsEtapesData, { rejectWithValue }) => {
	const { id_action, id_beaba, libelle, description, video_start_time, duration, id_etape_type, epreuve_brix_text, id_epreuve_brix, list_oms, list_beabas } = draftsEtapesData
	try {
		const response = await EtapesService.createDraftsEtapes({ id_brouillon_action: id_action, id_brouillon_beaba: id_beaba, libelle, description, video_start_time, duration, id_etape_type, epreuve_brix_text, id_epreuve_brix });
		if (list_oms && list_oms.length > 0 && response?.data ) {
			for (const oms of list_oms) {
				if (oms?.checked && !oms?.api) {
					await EtapesService.addDraftsEtapesOmsById(response.data?.id, oms?.id);
				}
			}
		}
		if (list_beabas && list_beabas.length > 0 && response?.data ) {
			for (const beabas of list_beabas) {
				if (!beabas?.api) {
					await EtapesService.addDraftsEtapesBeabasById(response.data?.id, beabas?.id_beaba, { video_start_time: beabas.video_start_time });
				}
			}
		}
		
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getEtapesTypesList = createAsyncThunk('etapes/getEtapesTypesList', async (_, { rejectWithValue }) => {
	try {
		const response = await EtapesService.getEtapesTypesList();
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

// ID
export const getDraftsEtapesById = createAsyncThunk('etapes/getDraftsEtapesById', async (draftsEtapesId, { rejectWithValue }) => {
	try {
		const response = await EtapesService.getDraftsEtapesById(draftsEtapesId);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getEtapesListById = createAsyncThunk('etapes/getEtapesListById', async (data, { rejectWithValue }) => {
	const { baseUrl, id } = data
	try {
		const response = await EtapesService.getEtapesListById(baseUrl, id);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDraftsEtapesListById = createAsyncThunk('etapes/getDraftsEtapesListById', async (data, { rejectWithValue }) => {
	const { baseUrl, id } = data
	try {
		const response = await EtapesService.getDraftsEtapesListById(baseUrl, id);
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateDraftsEtapesById = createAsyncThunk('etapes/updateDraftsEtapesById', async (draftsEtapesData, { rejectWithValue }) => {
	const { id_action, id_beaba, id_etape, libelle, description, video_start_time, duration, id_etape_type, epreuve_brix_text, id_epreuve_brix, list_oms, list_beabas, list_beabas_deleted } = draftsEtapesData
	try {
		const response = await EtapesService.updateDraftsEtapesById(id_etape, { id_brouillon_action: id_action, id_brouillon_beaba: id_beaba, libelle, description, video_start_time, duration, id_etape_type, epreuve_brix_text, id_epreuve_brix });
		if (list_oms && list_oms.length > 0 && response?.data ) {
			for (const oms of list_oms) {
				if (oms?.checked && !oms?.api) {
					await EtapesService.addDraftsEtapesOmsById(response.data?.id, oms?.id);
				} else if (!oms?.checked && oms?.api) {
					await EtapesService.deleteDraftsEtapesOmsById(response.data?.id, oms?.id);
				}
			}
		}
		if (list_beabas_deleted && list_beabas_deleted.length > 0) {
			for (const beabas_deleted of list_beabas_deleted) {
				await EtapesService.deleteDraftsEtapesBeabasById(response.data?.id, beabas_deleted?.id_beaba);
			}
		}
		
		if (list_beabas && list_beabas.length > 0 && response?.data ) {
			for (const beabas of list_beabas) {
				if (!beabas?.api) {
					await EtapesService.addDraftsEtapesBeabasById(response.data?.id, beabas?.id_beaba, { video_start_time: beabas.video_start_time });
				} else if (beabas?.api && beabas.updated) {
					await EtapesService.updateDraftsEtapesBeabasById(response.data?.id, beabas?.id_beaba, { video_start_time: beabas.video_start_time });
				}
			}
		}
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteDraftsEtapesById = createAsyncThunk('etapes/deleteDraftsEtapesById', async (draftsEtapesData, { rejectWithValue }) => {
	const { id_action, id_beaba, id_etape } = draftsEtapesData
	try {
		const response = await EtapesService.deleteDraftsEtapesById(id_etape, {id_brouillon_action: id_action, id_brouillon_beaba: id_beaba});
		return response.data;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const etapesSlice = createSlice({
	name: 'etapes',
	initialState,
	reducers: {
		resetMessageEtapes: (state) => {
			state.messageEtapes = ''
		},
		
		resetEtapesCreated: (state) => {
			state.etapesCreated = false
		},
		resetEtapesUpdatedId: (state) => {
			state.etapesUpdatedId = ''
		},
		resetEtapesDeleted: (state) => {
			state.etapesDeleted = false
		},
		
		resetEtapesDetails: (state) => {
			state.etapesDetails = []
		},
	},
    extraReducers: (builder) => {
		builder
			// createDraftsEtapes
			.addCase(createDraftsEtapes.pending, (state) => {
				state.loadingEtapesDetails = true
			})
			.addCase(createDraftsEtapes.fulfilled, (state, action) => {
				state.loadingEtapesDetails = false
				state.messageEtapes = "Étape créée avec succès"
				state.etapesCreated = true
			})
			.addCase(createDraftsEtapes.rejected, (state, action) => {
				state.loadingEtapesDetails = false
				state.messageEtapes = action.payload.message
			})
			
			// getEtapesTypesList
			.addCase(getEtapesTypesList.pending, (state) => {
				state.loadingEtapesTypesList = true
			})
			.addCase(getEtapesTypesList.fulfilled, (state, action) => {
				state.loadingEtapesTypesList = false
				state.listEtapesTypes = action.payload
			})
			.addCase(getEtapesTypesList.rejected, (state, action) => {
				state.loadingEtapesTypesList = false
				state.messageEtapes = action.payload.message
			})
			
			// getDraftsEtapesById
			.addCase(getDraftsEtapesById.pending, (state) => {
				state.loadingEtapesDetails = true
			})
			.addCase(getDraftsEtapesById.fulfilled, (state, action) => {
				state.loadingEtapesDetails = false
				state.etapesDetails = action.payload
			})
			.addCase(getDraftsEtapesById.rejected, (state, action) => {
				state.loadingEtapesDetails = false
				state.messageEtapes = action.payload.message
			})
			
			// getEtapesListById
			.addCase(getEtapesListById.pending, (state) => {
				state.loadingEtapesList = true
			})
			.addCase(getEtapesListById.fulfilled, (state, action) => {
				state.loadingEtapesList = false
				state.listEtapesApi = action.payload
			})
			.addCase(getEtapesListById.rejected, (state, action) => {
				state.loadingEtapesList = false
				state.messageEtapes = action.payload.message
			})
						
			// getDraftsEtapesListById
			.addCase(getDraftsEtapesListById.pending, (state) => {
				state.loadingEtapesList = true
			})
			.addCase(getDraftsEtapesListById.fulfilled, (state, action) => {
				state.loadingEtapesList = false
				state.listEtapesApi = action.payload
			})
			.addCase(getDraftsEtapesListById.rejected, (state, action) => {
				state.loadingEtapesList = false
				state.messageEtapes = action.payload.message
			})
			
			// updateDraftsEtapesById
			.addCase(updateDraftsEtapesById.pending, (state) => {
				state.loadingEtapesDetails = true
			})
			.addCase(updateDraftsEtapesById.fulfilled, (state, action) => {
				state.loadingEtapesDetails = false
				state.messageEtapes = "Étape modifiée avec succès"
				state.etapesUpdatedId = action.payload.id
			})
			.addCase(updateDraftsEtapesById.rejected, (state, action) => {
				state.loadingEtapesDetails = false
			})
			
			// deleteDraftsEtapesById
			.addCase(deleteDraftsEtapesById.pending, (state) => {
				//
			})
			.addCase(deleteDraftsEtapesById.fulfilled, (state, action) => {
				state.messageEtapes = "Étape supprimée avec succès"
				state.etapesDeleted = true
			})
			.addCase(deleteDraftsEtapesById.rejected, (state, action) => {
				state.messageEtapes = action.payload.message
			})
	},
})

export const { resetMessageEtapes, resetEtapesCreated, resetEtapesUpdatedId, resetEtapesDeleted, resetEtapesDetails } = etapesSlice.actions

export default etapesSlice.reducer