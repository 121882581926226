import fetch from "auth/FetchInterceptor";

const ActionsService = {};

ActionsService.createDraftsActions = function (actionsData) {
    return fetch({
        url: "/brouillons/actions",
        method: "post",
        data: actionsData,
    });
};

ActionsService.getActionsList = function () {
    return fetch({
        url: "/actions",
        method: "get",
        allowError422: true
    });
};

ActionsService.getDraftsActionsList = function () {
    return fetch({
        url: "/brouillons/actions",
        method: "get",
        allowError422: true
    });
};

ActionsService.getJobsList = function () {
    return fetch({
        url: "/metiers",
        method: "get",
    });
};

// ID
ActionsService.publishDraftsActionsById = function (draftsActionsId) {
    return fetch({
        url: "/brouillons/actions/" + draftsActionsId + "/publish",
        method: "post"
    });
};

ActionsService.getActionsById = function (actionsId) {
    return fetch({
        url: "/actions/" + actionsId,
        method: "get",
    });
};

ActionsService.getDraftsActionsById = function (draftsActionsId) {
    return fetch({
        url: "/brouillons/actions/" + draftsActionsId,
        method: "get",
    });
};

ActionsService.updateDraftsActionsById = function (draftsActionsId, draftsActionsData) {
    return fetch({
        url: "/brouillons/actions/" + draftsActionsId,
        method: "put",
        data: draftsActionsData,
    });
};

ActionsService.deleteActionsById = function (actionsId) {
    return fetch({
        url: "/actions/" + actionsId,
        method: "delete",
    });
};

ActionsService.deleteDraftsActionsById = function (actionsId) {
    return fetch({
        url: "/brouillons/actions/" + actionsId,
        method: "delete",
    });
};

// Images
ActionsService.addActionsThumbnailById = function (actionsId, actiosData) {
    return fetch({
        url: "/brouillons/actions/" + actionsId + "/images",
        method: "post",
        data: actiosData,
    });
};

ActionsService.deleteActionsThumbnailById = function (actionsId) {
    return fetch({
        url: "/brouillons/actions/" + actionsId + "/images",
        method: "delete",
    });
};

// Vidéos
ActionsService.addActionsVideoById = function (actionsId, actionsData) {
    return fetch({
        url: "/brouillons/actions/" + actionsId + "/videos",
        method: "post",
        data: actionsData,
    });
};

ActionsService.deleteActionsVideoById = function (actionsId, actionsData) {
    return fetch({
        url: "/brouillons/actions/" + actionsId + "/videos",
        method: "delete",
        data: actionsData,
    });
};

export default ActionsService;
