import fetch from "auth/FetchInterceptor";

const TravauxService = {};

TravauxService.createTravaux = function (travauxData) {
    return fetch({
        url: "/actions/sous-domaines",
        method: "post",
        data: travauxData,
    });
};

TravauxService.getTravauxList = function () {
    return fetch({
        url: "/actions/sous-domaines",
        method: "get",
        allowError422: true
    });
};

TravauxService.getTravauxWithQuestionsReponsesList = function () {
    return fetch({
        url: "/actions/sous-domaines/questions",
        method: "get",
        allowError422: true
    });
};

// ID
TravauxService.getTravauxById = function (travauxId) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId,
        method: "get",
    });
};

TravauxService.getTravauxQuestionsReponsesList = function (travauxId) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId + "/questions",
        method: "get",
    });
};

TravauxService.updateTravauxById = function (travauxId, travauxData) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId,
        method: "put",
        data: travauxData,
    });
};

TravauxService.deleteTravauxById = function (travauxId) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId,
        method: "delete",
    });
};

// Images
TravauxService.addTravauxPictoById = function (travauxId, travauxData) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId + "/images",
        method: "post",
        data: travauxData,
    });
};

TravauxService.deleteTravauxPictoById = function (travauxId) {
    return fetch({
        url: "/actions/sous-domaines/" + travauxId + "/images",
        method: "delete",
    });
};

// Thèmatiques
TravauxService.createQuestion = function (questionData) {
    return fetch({
        url: "/questions",
        method: "post",
        data: questionData,
    });
};

TravauxService.updateQuestion = function (questionId, questionData) {
    return fetch({
        url: "/questions/" + questionId,
        method: "put",
        data: questionData,
    });
};

TravauxService.deleteQuestion = function (questionId) {
    return fetch({
        url: "/questions/" + questionId,
        method: "delete",
    });
};

TravauxService.createQuestionReponse = function (questionId, reponseData) {
    return fetch({
        url: "/questions/" + questionId + "/reponses",
        method: "post",
        data: reponseData,
    });
};

TravauxService.updateQuestionReponse = function (questionId, reponseId, reponseData) {
    return fetch({
        url: "/questions/" + questionId + "/reponses/" + reponseId,
        method: "put",
        data: reponseData,
    });
};

TravauxService.deleteQuestionReponse = function (questionId, reponseId) {
    return fetch({
        url: "/questions/" + questionId + "/reponses/" + reponseId,
        method: "delete",
    });
};

export default TravauxService;
